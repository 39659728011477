import "./Styles/main.scss";
import "../components/cmsShared";
import { Slick } from "@/pages/vendors/vendor.slick";
import { createAniView } from "@/pages/vendors/vendor.aniView";
import "../components/baiduMap";
import "picturefill";
import "@/pages/base/divUtils";
import { CustomJqueryEvents } from "@/common/customJqueryEvents";
import { qrCodeGenerator } from "../components/cmsShared/qrCode";
import { CMS_WAP_URL_ROOT } from "@/consts";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { KnownCmsPageType} from "@/api";
import {loadContact} from "@/phone"

// Start Function
$(function () {
    createAniView($(".aniview"));

    const wapHomeUrl = `${CMS_WAP_URL_ROOT}/c${cmsGlobal.Cms.CompanyId}`;
    qrCodeGenerator(wapHomeUrl);

    if (cmsGlobal.Cms.IsCustomDomain && cmsGlobal.KnownPage === KnownCmsPageType.CmsProductDetail) {
        import(/* webpackChunkName: "imagePhotoswipe" */ "../../pages/modules/imagePhotoswipe");
    }

    $(".cat__post-link").off("click");

    $(".cat__item--has-children").on("mouseover", function () {
        $(this).find(".cat__child").show();
    });

    $(".cat__item--has-children").on("mouseout", function () {
        $(this).find(".cat__child").hide();
    });

    loadContact();
});

//首页banner滚动
$(async function () {
    const $bannerSlides = $(".banner-slides");
    async function initBannerSlides($bannerSlides: JQuery) {
        if ($bannerSlides.find(".banner-slides__item").length) {
            new Slick($bannerSlides, {
                dots: true,
                autoplay: true,
                autoplaySpeed: 5000,
                prevArrow: '',
                nextArrow: '',
            });
        }
        $bannerSlides.find("img").css("visibility", "visible");
    }
    await initBannerSlides($bannerSlides);

    $(document.body).on(CustomJqueryEvents.CmsModuleContentUpdatedEvent, async function (event, data) {
        if (data.data && data.data.CustomModuleType === "CarouselWithOutIntro") {
            initBannerSlides($bannerSlides);
        }
    });

    

      function  setupProductDetailSlides() {
        /* 产品详情页 用按钮控制图片左右滚动 */
        new Slick($(".product-detail__info-slide-imgs"), {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            infinite: false,
            asNavFor: ".product-detail__info-left-imgs-slide",
        });

        const slides = new Slick($(".product-detail__info-left-imgs-slide"), {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            asNavFor: ".product-detail__info-slide-imgs",
            focusOnSelect: true,
            infinite: false,
        });

        $(document).on(
            "click",
            ".product-detail__info-left-arrows .product-detail__info-left-arrows-next",
            function () {
                slides.slickNext();
            },
        );
        $(document).on(
            "click",
            ".product-detail__info-left-arrows .product-detail__info-left-arrows-prev",
            function () {
                slides.slickPrev();
            },
        );
        const length = $(".product-detail__info-left-imgs-slide-item").length;
        if (length > 4) {
            $(".product-detail__info-left-arrows a").removeClass("disabled");
        } else {
            $(".product-detail__info-left-arrows a").addClass("disabled");
        }
        $(".slidesjs-pagination-item a").text("");
    }

    setupProductDetailSlides();

    function setupIndexProductCategories() {
        const $productLists = $(".index-multicat__procontent-list");
        
        $(document.body).on("click", ".index-cat__list-item", function () {
            const $this = $(this);
            const index = $(".index-cat__list-item").index($this);

            console.log("what index", index);

            if ($this.is(".active")) return;
            $this.addClass("active");
            $this.siblings().removeClass("active");

            $productLists.removeClass("selected");
            $productLists.eq(index).addClass("selected");
        });
    }

    setupIndexProductCategories();
});

$(function () {
    setTimeout(function () {
        $(".cms-footer").css("opacity", 1);
    }, 0);
});
